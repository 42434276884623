<template>
  <div class="wrapper w-100 m-3">
    <div class="animated fadeIn">
      <b-row>
        <b-col cols="12" class="py-5" v-if="fetchingSpentListExploitation">
          <div class="text-center text-muted">
            <b-spinner></b-spinner>
            <br>
            Chargement de la liste des dépenses...
          </div>
        </b-col>
        <b-col cols="12" v-else>
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header">
              <i class="icon-layers mr-2"></i>
              <strong>
                Liste des dépenses
              </strong>
              <div class="card-header-actions">
                <b-button variant="primary" class="createSpent" @click="newSpent">
                  <i class="fa fa-plus"></i>
                  Enregistrer une dépense
                </b-button>
              </div>
              <b-modal title="Enregistrer une dépense" class="modal-primary" v-model="addModalSpent"
                       @hide="onAddModalSpentClose" size="lg">
                <b-form-row>
                  <b-col class="mr-4">
                    <c-input container-class="mb-3" label="Libellé de la dépense" placeholder="Ex: Dépense 1"
                             v-model="spentName" :state="spentNameState">
                      Veuillez saisir un nom
                    </c-input>
                    <c-input container-class="mb-3" type="quantity" label="Coût de la dépense" placeholder="Ex: 50000"
                             v-model="spentAmount" :unit="exploitationCurrency"
                             step="500" :state="spentAmountState">
                      Veuillez spécifier un coût
                    </c-input>
                    
                    <div class="d-inline-flex w-100">
                      
                      <c-input container-class="mb-3" type="select" label="Catégorie de la dépense"
                               v-model="spentCategory"
                               :options="listSpentCategories" :state="spentCategoryState">
                        <template slot="first">
                          <option :value="null">-- Selectionnez la catégorie de la dépense --</option>
                        </template>
                        Veuillez sélectionner une personne
                      </c-input>
                      <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter une nouvelle catégorie"
                                @click="addSpentCategory" :disabled="spentCategoryNewDisplay">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                    <c-input container-class="mb-3" label="" placeholder="Créer une nouvelle catégorie emetteur"
                             v-model="spentCategoryNew" :state="spentCategoryState" v-if="spentCategoryNewDisplay">
                      Veuillez saisir un nom
                    </c-input>
                    
                    
                    <c-input container-class="mb-3" type="datetime" label="Date d'enregistrement" v-model="spentDate"
                             :time.sync="spentTime">
                      Veuillez entrer une durée
                    </c-input>
                  </b-col>
                  <b-col>
                    <div class="d-inline-flex w-100">
                      <c-input container-class="mb-2" type="select" label="Emetteur de la dépense"
                               v-model="spentEmitter"
                               :options="spentsMembersList" :state="spentEmitterState">
                        <template slot="first">
                          <option :value="null">-- Selectionnez l'emetteur --</option>
                        </template>
                        Veuillez sélectionner une personne
                      </c-input>
                      <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                @click="addSpentEmitter" :disabled="spentEmitterNewDisplay">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                    <c-input container-class="mb-3" label="" placeholder="Créer un nouvel emetteur"
                             v-model="spentEmitterNew" :state="spentEmitterState" v-if="spentEmitterNewDisplay">
                      Veuillez saisir un nom
                    </c-input>
                    
                    <div class="d-inline-flex w-100">
                      <c-input container-class="mb-2" type="select" label="Bénéficiaire de la dépense"
                               v-model="spentReceiver"
                               :options="spentsMembersList" :state="spentReceiverState">
                        <template slot="first">
                          <option :value="null">-- Selectionnez le bénéficiaire --</option>
                        </template>
                        Veuillez sélectionner une personne
                      </c-input>
                      <b-button variant="dark" size="sm" pill class="add-button" title="Ajouter un nouvel emetteur"
                                @click="addReceiveEmitter" :disabled="spentReceiverNewDisplay">
                        <i class="fa fa-plus"></i>
                      </b-button>
                    </div>
                    <c-input container-class="mb-3" label="" placeholder="Créer un nouveau bénéficiaire"
                             v-model="spentReceiverNew" :state="spentReceiverState" v-if="spentReceiverNewDisplay">
                      Veuillez saisir un nom
                    </c-input>
                    
                    <c-input container-class="mb-3" type="textarea" label="Description de la dépense"
                             placeholder="Entrez une description..." v-model="spentDescription">
                    </c-input>
                  </b-col>
                  <b-form-file
                    v-model="spentFiles"
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    multiple
                  ></b-form-file>
                </b-form-row>
                
                <div slot="modal-footer" class="w-100 text-center">
                  <button-spinner variant="success" type="submit" class="px-4" @click="onSubmitAddSpent"
                                  :fetching=fetchingCreateSpent>
                    Enregistrer
                  </button-spinner>
                  <b-button variant="secondary" @click="addModalSpent = false" class="ml-2">
                    Annuler
                  </b-button>
                </div>
              </b-modal>
            </div>
            <b-row>
              <b-col cols="12" class="py-5" v-if="fetchingSpentList">
                <div class="text-center text-muted">
                  <b-spinner></b-spinner>
                  <br>
                  Chargement des données...
                </div>
              </b-col>
              <b-tabs v-model="spentListModel" v-else>
                <b-tab>
                  <template slot="title">
                    <div id="v-tab-0">
                      Productions ({{spentProductionsData.length}})
                      <info-message v-if="spentListModel==0">
                        Ce sont les dépenses liées aux productions
                      </info-message>
                    </div>
                  </template>
                  <c-table :table-data="spentProductionsData" :fields="spentProductionsFields"
                           :actions="spentActions"
                           :per-page=10 striped
                           outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucune dépense de production dans votre exploitation.</p>
                    </template>
                  </c-table>
                </b-tab>
                <b-tab>
                  <template slot="title">
                    <div id="v-tab-1">
                      Récoltes ({{spentHarvestsData.length}})
                      <info-message v-if="spentListModel==1">
                        Ce sont les dépenses liées aux récoltes
                      </info-message>
                    </div>
                  </template>
                  <c-table :table-data="spentHarvestsData" :fields="spentHarvestsFields"
                           :actions="spentActions"
                           :per-page=10 striped
                           outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucune dépense de récolte dans votre exploitation.</p>
                    </template>
                  </c-table>
                </b-tab>
                <b-tab>
                  <template slot="title">
                    <div id="v-tab-2">
                      Ventes ({{spentSalesData.length}})
                      <info-message v-if="spentListModel==2">
                        Ce sont les dépenses liées aux ventes
                      </info-message>
                    </div>
                  </template>
                  <c-table :table-data="spentSalesData" :fields="spentSalesFields"
                           :actions="spentActions"
                           :per-page=10 striped
                           outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucune dépense de vente dans votre exploitation.</p>
                    </template>
                  </c-table>
                </b-tab>
                <b-tab>
                  <template slot="title">
                    <div id="v-tab-3">
                      Autres dépenses ({{spentOthersData.length}})
                      <info-message v-if="spentListModel==3">
                        Ce sont les autres dépenses liées à l'exploitation
                      </info-message>
                    </div>
                  </template>
                  <c-table :table-data="spentOthersData" :fields="spentOtherFields"
                           :actions="spentActions"
                           :per-page=10 striped
                           outlined fixed>
                    <template slot="empty">
                      <p class="text-center">Aucune autre dépense de vente dans votre exploitation.</p>
                    </template>
                  </c-table>
                </b-tab>
              </b-tabs>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </div>
  
  </div>
</template>

<script>
  import {Api, price, Regex, Toast} from "../../../helpers";
  
  export default {
    name: "AccountingSpents",
    title: "PIA - Dépenses",
    data() {
      return {
        fetchingSpentList: false,
        spentListModel: 0,
        
        spentsAllData: [],
        
        spentFields: [
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountOrPrice', label: 'Montant'},
          {key: 'emitterName', label: 'Effectuée par'},
          {key: 'spentTypeAndName', label: 'Type et Nom'},
          {key: 'activityName', label: 'Activité'},
          {key: 'actions', label: 'Actions'},
        ],
        spentActions: [
          {type: 'success', icon: 'icon-size-fullscreen', title: 'Ouvrir', handler: this.spentDetails},
        ],
        
        spentProductionsFields: [
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountOrPrice', label: 'Montant'},
          {key: 'emitterName', label: 'Effectuée par'},
          {key: 'spentTypeAndName', label: 'Nom de la production'},
          {key: 'activityName', label: 'Activité'},
        ],
        spentHarvestsFields: [
          
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountOrPrice', label: 'Montant'},
          {key: 'emitterName', label: 'Effectuée par'},
          {key: 'spentTypeAndName', label: 'Nom de la récolte'},
          {key: 'activityName', label: 'Activité'},
        ],
        spentSalesFields: [
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountOrPrice', label: 'Montant'},
          {key: 'emitterName', label: 'Effectuée par'},
          {key: 'spentTypeAndName', label: 'Nom de la vente'},
          {key: 'formattedDate', label: 'Enregistrée le'},
        ],
        spentOtherFields: [
          {key: 'formattedDate', label: 'Enregistrée le'},
          {key: 'name', label: 'Libellé de la dépense'},
          {key: 'amountOrPrice', label: 'Montant'},
          {key: 'emitterName', label: 'Effectuée par'},
          {key: 'actions', label: 'Actions'},
        ],
        
        
        spentName: '',
        spentAmount: '',
  
        spentEditId:0,
        spentCategory: null,
        spentCategoryNew: "",
        spentCategoryNewDisplay: false,
        
        spentDate: "",
        spentTime: "",
        spentEmitter: null,
        spentReceiver: null,
        spentEmitterNew: "",
        spentReceiverNew: "",
        spentDescription: '',
        fetchingCreateSpent: false,
        submittedSpent: false,
        spentFiles: [],
        addModalSpent: false,
        editModalSpent: false,
        
        spentsMembers: [],
        spentEmitterNewDisplay: false,
        spentReceiverNewDisplay: false,
        
        spentCategories: [],
  
  
  
        myOptions: {
          useKeyboardNavigation: false,
          labels: {
            buttonSkip: 'Quittez',
            buttonPrevious: 'Précédent',
            buttonNext: 'Suivant',
            buttonStop: 'Terminé'
          }
        },
      }
    },
    created() {
      
      this.fetchingSpentList = true
      Api.get('/exploitation/spent/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentsAllData = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
          this.fetchingSpentList = false
        })
      Api.get('/exploitation/member/all', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentsMembers = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
      Api.get('/exploitation/spent-category/list', {
        exploitationId: this.exploitationId
      })
        .then(res => {
          if (res.data.status === 'success' && res.data.data) {
            this.spentCategories = res.data.data
          }
          else {
            this.error = res.data.error
          }
        })
        .catch(error => {
          this.error = {
            message: 'Echec de la connexion au serveur'
          }
        })
        .then(() => {
        })
    },
    mounted() {
    
    },
    watch: {},
    computed: {
      exploitationId() {
        return this.$store.getters.exploitationId
      },
      exploitationCurrency() {
        return price()
      },
      tabs() {
        return [
          {
            icon: 'fa fa-spinner',
            title: 'Productions',
            timeTitle: 'Débutée le',
            spents: this.spentsAllData.filter(spent => (spent.spentEmitter && spent.production)),
            type: 'running'
          },
          {
            icon: 'fa fa-stop-circle',
            title: 'Récoltes',
            timeTitle: 'Terminée le',
            spents: this.spentsAllData.filter(item => item.status.name == 'TERMINATED'),
            type: 'terminated'
          },
          {
            icon: 'fa fa-clock-o',
            title: 'Ventes',
            timeTitle: 'Planifiée pour le',
            spents: this.spentsAllData.filter(item => item.status.name == 'PLANNED'),
            type: 'planned'
          },
          {
            icon: 'fa fa-th',
            title: 'Autres dépenses',
            timeTitle: 'Abandonnée le',
            spents: this.spentsAllData.filter(spent => spent.status.name == 'ABANDONED'),
            type: 'abandoned'
          },
        ]
      },
      spentProductionsData() {
        return this.spentsAllData.filter(spent => spent.production).map(spent => ({
          name: !spent.spentEmitter ? "N\\D" : spent.name,
          amountOrPrice: (spent.spentEmitter ? spent.amount : spent.cost) + " " + this.exploitationCurrency,
          emitterName: !spent.spentEmitter ? "N\\D" : spent.spentEmitter.name,
          spentTypeAndName: spent.production.name,
          activityName: spent.spentEmitter ? "N\\D" : spent.name,
          // todo i've removed the hours and minutes to the time but they are still available
          formattedDate: spent.spentDate.split(' ')[0],
          ...spent
        }))
      },
      spentHarvestsData() {
        return this.spentsAllData.filter(spent => spent.harvest).map(spent => ({
          name: !spent.spentEmitter ? "N\\D" : spent.name,
          amountOrPrice: (spent.spentEmitter ? spent.amount : spent.cost) + " " + this.exploitationCurrency,
          emitterName: !spent.spentEmitter ? "N\\D" : spent.spentEmitter.name,
          spentTypeAndName: spent.harvest.name,
          activityName: spent.spentEmitter ? "N\\D" : spent.name,
          formattedDate: spent.spentDate.split(' ')[0],
          ...spent
        }))
      },
      spentSalesData() {
        return this.spentsAllData.filter(spent => spent.sale).map(spent => ({
          name: spent.name,
          amountOrPrice: spent.amount + " " + this.exploitationCurrency,
          emitterName: spent.spentEmitter.name,
          spentTypeAndName: spent.sale.name,
          formattedDate: spent.spentDate.split(' ')[0],
          ...spent
        }))
      },
      spentOthersData() {
        return this.spentsAllData.filter(spent => spent.isExploitation).map(spent => ({
          name: spent.name,
          amountOrPrice: spent.amount + " " + this.exploitationCurrency,
          emitterName: spent.spentEmitter.name,
          formattedDate: spent.spentDate.split(' ')[0],
          ...spent
        }))
      },
      spentNameState() {
        return !this.submittedSpent || this.spentName.trim().length >= 3 ? null : false
      },
      spentAmountState() {
        return !this.submittedSpent || Regex.isPositiveNumber(this.spentAmount) ? null : false
      },
      spentCategoryState() {
        return !this.submittedSpent || (this.spentCategory != null || this.spentCategoryNew.trim().length >=3) ? null : false
      },
      spentEmitterState() {
        return !this.submittedSpent || (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3) ? null : false
      },
      spentReceiverState() {
        return !this.submittedSpent || (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3) ? null : false
      },
      listSpentCategories() {
        return this.spentCategories.map(cat => ({
          text: cat.name,
          value: cat
        }))
      },
      spentsMembersList() {
        return this.spentsMembers.map(member => ({
          text: member.name,
          value: member
        }))
      }
    },
    methods: {
      
      onAddModalSpentClose() {
        this.editModalSpent = false
        
        this.spentName = ''
        this.spentAmount = ''
        this.spentDescription = ''
        this.submittedSpent = false
        this.spentDate = ""
        this.spentTime = ""
        this.spentReceiver = null
        this.spentReceiverNew = ""
        this.spentReceiverNewDisplay = false
        this.spentEmitter = null
        this.spentEmitterNew = ""
        this.spentCategoryNew = ""
        this.spentCategoryNewDisplay = false
      },
      newSpent() {
        this.addModalSpent = true
      },
      validSpent() {
        return this.spentName.trim().length >= 3 &&
          Regex.isPositiveNumber(this.spentAmount)
          && (this.spentCategory != null || this.spentCategoryNew.trim().length >= 3)
          && (this.spentEmitter != null || this.spentEmitterNew.trim().length >= 3)
          && (this.spentReceiver != null || this.spentReceiverNew.trim().length >= 3)
      },
      getFiles(files) {
        return Promise.all(files.map(file => {
          var reader = new FileReader()
          return new Promise((resolve, reject) => {
            reader.onload = (ev => {
              resolve(ev.target.result)
            })
            reader.readAsDataURL(file)
          })
        }))
      },
      async onSubmitAddSpent() {
        this.submittedSpent = true
        if (!this.validSpent())
          return
        
        this.fetchingCreateSpent = true
        
        const base64Files = await this.getFiles(this.spentFiles)
        
        // recuperation de l'emetteur
        const emitter = {
          isExist: this.spentEmitter ? true : false,
          user: this.spentEmitter || this.spentEmitterNew
        }
        // recuperation du beneficiaire
        const receiver = {
          isExist: this.spentReceiver ? true : false,
          user: this.spentReceiver || this.spentReceiverNew
        }
  
  
        // retrieving the old|new mode
        const cat = {
          isExist: this.spentCategory ? true : false,
          category: this.spentCategory || this.spentCategoryNew
        }
  
        let createOrUpdate = ""
        if(this.editModalSpent) createOrUpdate = 'update'
        else createOrUpdate = 'create'
        
        Api.post(`/exploitation/spent/${createOrUpdate}`, {
          exploitationId: this.exploitationId,
          spentId: this.spentEditId,
          name: this.spentName,
          amount: this.spentAmount,
          spentEmitter: emitter,
          spentReceiver: receiver,
          spentCategory: cat,
          spentDate: this.spentDate + " " + this.spentTime,
          description: this.spentDescription,
          attachments: base64Files,
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              if(createOrUpdate == 'create'){
                this.spentsAllData.push(res.data.data)
                this.addModalSpent = false
                Toast.success('Dépense enregistrée avec succès')
              }else {
                this.spentsAllData = this.spentsAllData.filter(ca => ca.id != this.spentEditId)
                this.spentsAllData.push(res.data.data)
                this.addModalSpent = false
                Toast.success('Dépense modifiée avec succès')
              }
              this.refreshCategoryList()
              this.refreshSpentCategoryList()
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
            this.fetchingCreateSpent = false
          })
      },
      addSpentCategory() {
        this.spentCategoryNewDisplay = true
      },
      addSpentEmitter() {
        this.spentEmitterNewDisplay = true
      },
      addReceiveEmitter() {
        this.spentReceiverNewDisplay = true
      },
      refreshCategoryList() {
        
        Api.get('/exploitation/member/all', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentsMembers = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      refreshSpentCategoryList(){
    
        Api.get('/exploitation/spent-category/list', {
          exploitationId: this.exploitationId
        })
          .then(res => {
            if (res.data.status === 'success' && res.data.data) {
              this.spentCategories = res.data.data
            }
            else {
              this.error = res.data.error
            }
          })
          .catch(error => {
            this.error = {
              message: 'Echec de la connexion au serveur'
            }
          })
          .then(() => {
          })
      },
      spentDetails(spent){
  
        this.addModalSpent = true
        this.editModalSpent = true
  
        console.log(spent)
        
        
        this.spentEditId = spent.id
        this.spentName = spent.name
        this.spentAmount = spent.amount
        this.spentDescription = spent.description
        this.spentDate = spent.spentDate.split(' ')[0].split('/').reverse().join('-')
        this.spentTime = spent.spentDate.split(' ')[1]
        this.spentEmitter = this.spentsMembers.find(m => m.id == spent.spentEmitter.id
          && m.user == spent.spentEmitter.user)
        this.spentReceiver = this.spentsMembers.find(m => m.id == spent.spentReceiver.id
          && m.user == spent.spentReceiver.user)
        this.spentCategory = this.spentCategories.find(entry => entry.id == spent.spentCategory.id)
      },
      nextComposantToVisit(){
        this.$router.push('/exploitation/' + this.exploitationId + '/accounting/resume')
      }
    }
  }
</script>

<style scoped>
  
  .add-button{
    height: 30px;
    width: 30px;
    margin-top: 23px;
    margin-left: 10px;
    margin-right: 2px;
  }

</style>
